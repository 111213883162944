// gatsby-ssr.js and gatsby-browser.js
import React from "react";

export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([
    <script
      key="qgiv-embed-script"
      src="https://secure.qgiv.com/resources/embed.js"
      async
    ></script>,
  ]);
};
